@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #8AA2A9;
    border-color: #8AA2A9 transparent #8AA2A9 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
